.App {
  text-align: center;

  

}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  display: inline;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
/*  background-color: #fff;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
}

.App-link {
  color: #61dafb;
}

.awa-button {
  @apply bg-gray-800 hover:bg-gray-900 text-white py-1 text-base rounded my-1 drop-shadow-md;
  width: 100px;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
