@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /*background-image: url("output-awadao-large.png");*/

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 350px) {
    body {
        background: url("output-awadao-small.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

@media (min-width: 500px) {
    body {
        background: url("output-awadao-medium.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

@media (min-width: 1000px) {
    body {
        background: url("output-awadao-large.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}